//components
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'
import { debounce } from 'lodash'

export default {
  name: "range",
  props: {
    dataRange: {
      type: Object,
      default: ()=> {}
    },
    type: {
      type: String,
      default: ''
    }
  },

  components: {
    VueSimpleRangeSlider,
  },
  data(){
    return{
      localRange: [this.dataRange.min, this.dataRange.max],
      prevLocalRange: [this.dataRange.min, this.dataRange.max],
      number: 100,
      keysType: ['min', 'max']
    }
  },
  created() {

  },
  computed:{

  },
  watch: {
    dataRange: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.localRange = [this.getValue('min'), this.getValue('max')]
          this.prevLocalRange = [this.getValue('min'), this.getValue('max')]
        }
      }
    },
    localRange: {
      handler(newVal) {
        if (newVal) {
          this.handleInput(newVal)
        }
      }
    }
  },
  methods:{
    getValue(subType) {
      let query = this.$route.query;
      let type  = `${this.type}[${subType}]`
      return query && query[type] ? query[type] : this.dataRange[subType];
    },
    changeFilter(range) {
      let index = null;
      if (this.prevLocalRange[0] !== range[0]){
        this.prevLocalRange[0] = range[0];
        index = 0;
      } else if (this.prevLocalRange[1] !== range[1]){
        this.prevLocalRange[1] = range[1];
        index = 1;
      }

      if (index !== null) {
        this.$emit('change-filter', {
          data: range[index].toString(),
          key: this.keysType[index],
          type: this.type
        })
      }
    },
    handleInput: debounce(function (range) {
      this.changeFilter(range)
    }, 100),
  }
}