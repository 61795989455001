//components

export default {
  name: "attributes",
  props: {
    listData: {
      type: Object,
      default: ()=> {}
    },
    type: {
      type: String,
      default: ''
    }
  },

  components: {
  },
  data(){
    return{
      localList: []
    }
  },
  created() {

  },
  computed:{

  },
  watch: {
    listData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.localList = this.listData.attributeValue.map(item => {
            return Object.assign({},item, {value: this.getValue(item.id)})
          })
        }
      }
    }
  },
  methods:{
    getValue(id) {
      let query = this.$route.query;
      let arr = query && query.attributes ? query.attributes.split(',') : [];

      return arr.find(item => item === id.toString()) !== undefined;
    },
    changeFilter(id) {
      this.$emit('change-filter', {
        data: id.toString(),
        key: 'attributes'
      })
    }
  }
}