import {mapActions, mapGetters, mapMutations} from "vuex";

//components
import productCard from "@/components/product-card/index.vue";
import filterBar from "@/modules/catalog/filter-bar/index.vue";
import burgerCategory from "@/components/header/components/burger-category/index.vue";
//mixins
import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
  name: "catalog",
  mixins: [dynamicHead],
  components: {
    productCard,
    filterBar,
    burgerCategory,
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) {
          this.crumbs = [
            {name: 'home', title: this.$t('mainLabel'), slug: ''},
          ]
          this.page = 1
          this.resetProduct()
          this.fetchProducts({slug: this.$route.params.slug, query: this.serialize(this.$route.query), page: this.page}).then(() => {
            this.setParams()
            this.setMeta()
            this.params.schema = this.micro
            this.setCatalogSchema()
          })
        }
      }
    },
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        console.log(newVal.mainLabel);
      }
    },
    'categoriesList': {
      handler(newVal) {
        if (newVal) {
          this.setParams()
          this.setMeta()
          this.params.schema = this.micro
          this.setCatalogSchema()
        }
      }
    },
    '$route.params.slug': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.crumbs = [
            {name: 'home', title: this.$t('mainLabel'), slug: ''},
          ]
          this.findNestedObjectsBySlug(this.$route.params.slug, this.categoriesMenuList)
        }
      }
    },
    'categoriesMenuList': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.crumbs = [
            {name: 'home', title: this.$t('mainLabel'), slug: ''},
          ]
          this.findNestedObjectsBySlug(this.$route.params.slug, this.categoriesMenuList)
        }
      }
    },
  },
  data() {
    return {
      isDesktop: true,
      isDesktopCategory: true,
      scroller: 0,
      updateCrumbs: false,
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
      ],
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
        schema: null
      },
      page: 1,
    }
  },
  created() {
    this.page = this.$route.query.page ? this.$route.query.page : 1
    this.resetSkip()
    this.resetProduct()
    this.fetchProducts({slug: this.$route.params.slug, query: this.serialize(this.$route.query), page: this.page}).then(() => {
      this.setParams()
      this.setMeta()
      this.params.schema = this.micro
      this.setCatalogSchema()
    })
    // this.fetchFilters().then(() => {
    //
    // }).catch((error) => {
    //   console.log(error)
    // })
  },
  mounted() {
    window.innerWidth <= 960 ? this.isDesktop = false : this.isDesktop = true;
    window.innerWidth <= 960 ? this.isDesktopCategory = false : this.isDesktopCategory = true;
  },
  computed: {
    ...mapGetters({
      filters: 'category/filters',
      products: 'category/products',
      micro: 'category/micro',
      limit: 'category/limit',
      categoriesList: 'category/catalogCategories',
      categoriesMenuList: 'category/categories',
      showPagination: 'category/showPagination',
    }),
    loadLink(){
      console.log(this.$router.resolve(this.$localize({
        name: 'catalog',
        params: {slug: this.$route.params.slug},
        query: {page: (this.page + 1)}
      })));
      return this.$router.resolve(this.$localize({name: 'catalog', params: {slug: this.$route.params.slug}, query: {page: (+this.page + 1)}}))
    },
    categoryTitle() {
      if (this.$route.params.slug) {
        if (this.categoriesList.length) {
          let categoryId = this.$route.params.slug
          let categoryTitles = []
          let category = this.categoriesList.filter(el => el.slug === categoryId)
          let categoryTitle = category[0].title
          categoryTitles.push(categoryTitle)
          return `${categoryTitles.join(' > ')}`
        }
      } else {
        return this.$t('catalog')
      }
    },
    selectedCategory() {
      if (this.categoriesList && this.categoriesList.length) {
        // console.log(this.categoriesList.find(el => el.slug === this.$route.params.slug));
        return this.categoriesList.find(el => el.slug === this.$route.params.slug)
      } else return null
    },
    selectedMobileCategory() {
      if (this.categoriesMenuList && this.categoriesMenuList.length) {
        // console.log(this.categoriesList.find(el => el.slug === this.$route.params.slug));
        return this.categoriesMenuList.find(el => el.slug == this.$route.params.slug)
      } else return null
    },
    parentCategory() {
      if (this.$route.params.slug) {
        if (this.categoriesList.length) {
          let item = this.categoriesList.find(el => el.slug === this.$route.params.slug)
          if (item.parentId === 0) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    }

  },
  methods: {
    ...mapActions({
      fetchFilters: 'category/GET_FILTERS',
      fetchProducts: 'category/GET_PRODUCTS',
    }),
    ...mapMutations({
      changeSkip: 'category/CHANGE_PAGE_SKIP',
      resetSkip: 'category/RESET_SKIP',
      resetProduct: 'category/RESET_PRODUCTS',
    }),

    findNestedObjectsBySlug(slug, objects) {
      const data = [];
      function traverseObject(object, path) {
        if (object.slug === slug) {
          data.push({object, path});
        }
        if (object.child.data.length) {
          object.child.data.forEach((child, index) => {
            traverseObject(child, [...path, {index, object: child}]);
          });
        }
      }
      objects.forEach((object, index) => {
        traverseObject(object, [{index, object}]);
      });
      let crumbs = data[0].path.map(el => {
        let obj = el.object
        let {title, slug} = obj
        return {title, slug}
      })
      crumbs.forEach(el => {
        el.name = 'catalog'
        this.crumbs.push(el)
      })
      this.updateCrumbs = true
      setTimeout(()=>{
        this.updateCrumbs = false
      },10)
    },
    setParams() {
      let categoryMeta = {
        title: '',
        description: '',
        keywords: '',
        image: '',
      }
      if (this.$route.params.slug) {
        if (this.categoriesList.length) {
          let categoryId = this.$route.params.slug
          let category = this.categoriesList.filter(el => el.slug === categoryId)
          categoryMeta.title = category[0].metaTitle
          categoryMeta.description = category[0].metaDescription
          categoryMeta.keywords = category[0].metaKeywords
        }
      }
      this.params.title = categoryMeta.title;
      this.params.description = categoryMeta.description;
      this.params.keywords = categoryMeta.keywords;
      this.params.image = '';
    },
    loadMore() {
      this.scroller = self.pageYOffset || (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop)
      // this.changeSkip()
      this.page++
      this.fetchProducts({slug: this.$route.params.slug, query: this.serialize(this.$route.query), page: this.page}).then(() => {
        window.scrollTo({
          top: this.scroller,
          behavior: "smooth"
        });
      })
    },
    serialize(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }
  }
}