//components

export default {
  name: "sort",
  props: {},

  components: {
  },
  data(){
    return{
      localList: [
        {
          key: 'clear',
          attrValues: ['']
        },
        {
          key: 'title',
          attrValues: ['asc', 'desc']
        },
        {
          key: 'price',
          attrValues: ['asc', 'desc']
        }
      ],
      localValue: '',
    }
  },
  created() {
    this.localValue = this.getValue();
  },
  computed:{

  },
  watch: {},
  methods:{
    getValue() {
      let query = this.$route.query;
      let querySortKey = Object.keys(query).filter(key => {
        return key.indexOf('sort') > -1
      })[0]

     if (querySortKey) {
       let sortKey = this.localList.find(item => {
         return querySortKey.indexOf(item.key) > -1
       })
       return sortKey ? `${sortKey.key}_${query[querySortKey]}` : ''
     } else {
       return  ''
     }

    },
    changeSort(key, attrValue) {
      this.$emit('change-sort', {
        data: attrValue,
        key: key
      })
    }
  }
}