import {directive as onClickaway} from 'vue-clickaway';
import { cloneDeep } from 'lodash'

//components
import list from "@/modules/catalog/filter-bar/components/list/index.vue";
import attributes from "@/modules/catalog/filter-bar/components/attributes/index.vue";
import range from "@/modules/catalog/filter-bar/components/range/index.vue";
import sort from "@/modules/catalog/filter-bar/components/sort/index.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "filter-bar",

  props: {
    list: {
      type: Object,
      default: ()=> {}
    },
  },

  components: {
    list,
    attributes,
    range,
    sort
  },

  directives: {
    onClickaway: onClickaway,
  },

  data(){
    return{
      barBotData: {
        type: null,
        secondaryType: null,
        data: null
      },
      selectedCount: {},

      sort: {}
    }
  },

  created() {
    this.updateCounters(this.$route.query);
  },

  computed:{
    ...mapGetters({
      filters: 'category/filters',
    }),

  },

  watch: {
    '$route.query'(newVal){
      if(newVal){
        this.updateCounters(newVal);
        // this.fetchProducts({slug: this.$route.params.slug, query: this.serialize(this.$route.query), page: this.page})
      }
    }
  },

  methods:{
    ...mapActions({
      fetchProducts: 'category/GET_PRODUCTS',
    }),

    openFilter(data, type, secondaryType){
      if (secondaryType !== this.barBotData.secondaryType) {
        this.barBotData.type = type;
        this.barBotData.secondaryType = secondaryType;
        this.barBotData.data = data;
      } else {
        this.barBotData.type = null;
        this.barBotData.secondaryType = null;
        this.barBotData.data = null;
      }
    },

    closeBarBot() {
      this.barBotData.type = null;
      this.barBotData.secondaryType = null;
      this.barBotData.data = null;
    },

    onEventFilter(filter) {
      let {data, key} = filter;
      let urlQuery = cloneDeep(this.$route.query);
      if (urlQuery[key]) {
        let arrValues = urlQuery[key].toString().split(',');
        let indexVal = arrValues.findIndex((item) => {
          return item === data
        })
        if (indexVal > -1) {
          arrValues.splice(indexVal, 1)
          if (arrValues.length) {
            urlQuery[key] = arrValues.join(',');
          } else {
            delete urlQuery[key];
          }
        } else {
          urlQuery[key] = urlQuery[key] + ',' + data;
        }
      } else {
        urlQuery[key] = data;
      }
      this.$router.replace({
        name: 'catalog',
        params: this.$route.params,
        query: urlQuery
      })
    },

    onEventRange(filter) {
      let {data, key, type} = filter;
      let urlQuery = cloneDeep(this.$route.query);
      let queryValue = urlQuery[`${type}[${key}]`]
      if (queryValue) {
        if (data === this.barBotData.data[key].toString()) {
          delete urlQuery[`${type}[${key}]`];
        } else {
          urlQuery[`${type}[${key}]`] = data;
        }
      } else {
        urlQuery[`${type}[${key}]`] = data;
      }
      this.$router.replace({
        name: 'catalog',
        params: this.$route.params,
        query: urlQuery
      })
    },

    serialize(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    updateCounters(urlQuery) {
      if (urlQuery) {
        Object.keys(urlQuery).forEach(key=> {
          if (key === 'categories' || key === 'collections') {
            this.$set(this.selectedCount, [key], urlQuery[key].toString().split(',').length)
          } else if (key === 'attributes'){
            let attributes = urlQuery[key].split(',')
            let attrGroups = this.filters.attributes.filter(filter => filter.attributeValue.length)

            attrGroups.forEach(attrGroup => {
              let count = 0;

              attrGroup.attributeValue.forEach(attrValue => {
                attributes.forEach(attr => {
                  count = Number(attr) === attrValue.id ? count + 1 : count
                }, 0)
              });
              this.$set(this.selectedCount, [`attributes_${attrGroup.id}`], count)
            });
          }
        })
      }
    },
    openSort(){
      this.barBotData.type = this.barBotData.type !== 'sort' ? 'sort' : null ;
      this.barBotData.secondaryType = this.barBotData.secondaryType !== 'sort' ? 'sort' : null ;
      this.barBotData.data = null;
    },

    onEventSort(sort) {
      let {data, key} = sort;
      let urlQuery = cloneDeep(this.$route.query);
      let querySortKeys = Object.keys(urlQuery).filter(key => {
        return key.indexOf('sort') > -1
      })
      querySortKeys.forEach(sortKey => {
        delete urlQuery[sortKey];
      })

      if (data.length) {
        urlQuery[`sort[${key}]`] = data;
      }

      this.$router.replace({
        name: 'catalog',
        params: this.$route.params,
        query: urlQuery
      })
    },
  }
}